import '../styles/index.scss';

$(document).ready(function(){

	employee_slider();
	slider_md();
	slider_sm();

	$(window).on("resize", function(event){
		slider_md();
		slider_sm();
	});

	$('.menu__trigger').click(function(){
		$('header').toggleClass('open');
	});

	$('.target_modal').click(function(e){
		e.preventDefault();
		var target = $(this).attr('data-target');
		$('.modal[id="'+target+'"]').toggleClass('open');
	});

	$('.modal_close').click(function(){
		$(this).closest('.modal').removeClass('open');
	});

	$("form.page-filter").submit(function (e) {
		$(this).closest('.modal').removeClass('open');
	});

	$('.sidebar__item').click(function(e){
		if(e.target == e.currentTarget) {
	        $(this).closest('.modal').removeClass('open');
	    }
	});

	$('.faq__item .item__head').click(function(){
		$(this).parent().toggleClass('open');
	});

	$('.questions__item .item__head').click(function(){
		$(this).parent().toggleClass('open');
	});

	$('.mobile__filter .title').click(function(){
		$(this).closest('.mobile__filter').toggleClass('open');
	});

	$('header .nav__menu ul:not(".mobile__top_menu") li.parent > a').click(function(e){
		e.preventDefault();
		$(this).closest('.parent').toggleClass('open');
	});

	$('header .nav__menu ul.mobile__top_menu li.parent > a > span > i').click(function(e){
		e.preventDefault();
		$(this).closest('.parent').toggleClass('open');
	});

	$('header .trigger_search').click(function(e){
		e.preventDefault();
		$('.search__fixed').toggleClass('open');
		$('.search__fixed').find('input').focus();
	});

	$('footer .footer__title').click(function(){
		$(this).toggleClass('open');
	});

	$('#accept-all-cookies').click(function(e){
		e.preventDefault();
		$('.cookie__notice').addClass('hidden');
	});

	$('#newsletter_name_start').keyup(function (){
	    $('.fill-mail').find('input').val($(this).val());
	});

	$('#newsletter_name_start').on('keyup', function(e) {
	  if (e.keyCode == 13) {
	  	$(this).blur();
	    $('.modal[id="modal-newsletter"]').toggleClass('open');
	    setTimeout(function() {
	    	$('.modal #focus-name').focus();
	    }, 100);
	  }
	});

	$(window).scroll(function() {
	    var height = $(window).scrollTop();
	    if (height > 100) {
	        $('#scrollTop').addClass('show');
	    } else {
	        $('#scrollTop').removeClass('show');
	    }
	});
	$(document).ready(function() {
	    $("#scrollTop").click(function(event) {
	        event.preventDefault();
	        $("html, body").animate({ scrollTop: 0 }, 200);
	        return false;
	    });

		$('.closedropdown, .filter__section.tag__section .tag__item label').click(function(event) {
			$('.filter__section.open').removeClass('open');
		});

	});
	var $checkboxes = $("input[type=checkbox][id^='checkbox']");

	$checkboxes.on("click", function() {
	    var checkedState = this.checked
	    var $selected = $("input[id='"+ this.id +"']");
	    $selected.each(function() {
	        this.checked = checkedState;
	    });
	});

	if ($(window).width() > 991) {
		$('.filter__section .filter__title').click(function(){
			$(this).parent().toggleClass('open');
			$(this).parent().siblings().removeClass('open');
		});
	}
});

if ( $('#uploadBtn').length ) {
	document.getElementById("uploadBtn").onchange = function () {
	    document.getElementById("uploadFile").value = this.value;
	};
}

$(window).on('scroll', function() { 
	if ( $('.main__content').length ) {
	    if ($(window).scrollTop() >= $('.main__content').offset().top + $('.main__content').outerHeight() - (window.innerHeight - 250)) { 
	        $('.mobile__filter__trigger').addClass('hide');
	    } else {
	    	$('.mobile__filter__trigger').removeClass('hide');
	    }
	}
}); 

var pattern = /\d{2}\/\d{2}\/\d{4}/;
document.querySelector("input").addEventListener("keyup", function(ev){
  var value = ev.target.value;
  if(!pattern.test(value)){
  }
});

$(".scroll-element").click(function() {
	var className = $(this).attr('data-scroll-target');
	$(this).closest('.modal').removeClass('open');
    $([document.documentElement, document.body]).animate({
        scrollTop: $('#' + className).offset().top - 110
    }, 1000);
});

function employee_slider() {
	$('.employee_slider').slick({
	  	infinite: true,
	  	slidesToShow: 1,
	  	slidesToScroll: 1,
	  	arrows: false,
	  	autoplay: false,
  		autoplaySpeed: 3000,
  		responsive: [
		   	{
		     	breakpoint: 767,
		     	settings: {
		       		autoplay: false,
		     	}
		   	}
	   	]
	});
}

function slider_md() {
	if ($(window).width() < 991) {
		if (!$('.set__slider.init_md').hasClass('slick-initialized')) {
			$('.set__slider.init_md').slick({
			  	infinite: false,
			  	slidesToShow: 2,
			  	slidesToScroll: 1,
			  	arrows: false,
			  	dots: true,
			  	adaptiveHeight: true,
			  	responsive: [
				   	{
				     	breakpoint: 767,
				     	settings: {
				       		slidesToShow: 1,
				     	}
				   	}
			   	]
			});
		}
	} else {
		$('.set__slider.init_md.slick-initialized').each(function() {
	        $(this).slick('unslick');
	    });
	}
}
function slider_sm() {
	if ($(window).width() < 767) {
		if (!$('.set__slider.init_sm').hasClass('slick-initialized')) {
			$('.set__slider.init_sm').slick({
			  	infinite: false,
			  	slidesToShow: 1,
			  	slidesToScroll: 1,
			  	arrows: false,
			  	dots: true,
			  	adaptiveHeight: true,
			});
		}
	} else {
		$('.set__slider.init_sm.slick-initialized').each(function() {
	        $(this).slick('unslick');
	    });
	}
}
